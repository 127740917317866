import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import { FaArrowAltCircleRight} from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";
import {Link} from 'gatsby'
import Star from "../components/star";

const Resources = () => {
  //Landing page for Resources
  const resources=[
    {
      title:'The Swift Launch Litho',
      image:'sat.png',
      page:'swiftLitho',
      description:"The Swift launch litho is a one-page litho that describes the science of NASA's Swift mission",
      internalText:[
        "The Swift launch litho is a one-page litho that describes the science of NASA's Swift mission, as well as providing an activity for students to do on the back. It was produced in September 2004 for distribution at the Swift launch.",
      ],
      pdf:{
        pdf:'swiftLithioPdf.pdf',
        accessible:'swiftLithioPdfDisability.pdf'
      }
    },
    {
      title:'The Swift Launch Fact Sheet',
      image:'swirl.png',
      page:'launchFactSheet',
      description:"The Swift Launch fact sheet is a four-page color brochure that describes the science of NASA's Swift Mission",
      internalText:["The Swift launch fact sheet is a four-page color brochure that describes the science of NASA's Swift mission, as well as providing tables that summarize the instrumental parameters and the follow-up team participants. It was produced in September 2004 for distribution at the Swift launch and was updated two years after launch, in November 2006."],
      pdf:{
        pdf:'launchFactsheet.pdf',
        accessible:'launchFactsheetDisability.pdf'
      }
    },
    {
      title:'The Swift Fact Sheet',
      image:'burst.png',
      page:'factSheet',
      description:'This fact sheet briefly describes the Swift mission, its intruments, and ground system',
      internalText:["This fact sheet briefly describes the Swift mission, its instruments, and ground system. Also included are tables listing the instrument parameters and a listing of the major institutions involved. The fact sheet will be updated as needed. Last update: July 2004."],
      pdf:{
        pdf:'factsheet.pdf',
        accessible:null
      }
    },
    {
      title:'The 2001 Swift Brochure',
      image:'sat2.png',
      page:'brochure2001',
      description:'Catching Gamma-Ray bursts on the Fly',
      internalText:["Catching Gamma-Ray Bursts on the Fly ... Gamma-ray bursts are distant explosions that represent the greatest release of energy the Universe has seen since the Big Bang. Satellites detect about one of these bursts a day, and scientists do not know what causes them. Now there is a satellite dedicated to addressing the gamma-ray burst mystery. Swift, a NASA mission with international participation, is planned for a 2003 launch. Swift will find hundreds of these powerful flashes of light each year."],
      pdf:{
        pdf:'2001Brochure.pdf',
        accessible:'2001BrochureDisability.pdf'
      }
    },
    {
      title:'The Swift Glider',
      image:'glider.png',
      page:'glider',
      description:'The Swift Satellite is named for an agile bird that catches its food on the fly. Now you can build a paper airplane designed to look like a Swift! The kit comes with pre-printed heavy-duty colored paper, and assembly only takes a few minutes.',
      internalText:["The Swift Satellite is named for an agile bird that catches its food on the fly. Now you can build a paper airplane designed to look like a Swift! The kit comes with pre-printed heavy-duty colored paper, and assembly only takes a few minutes."],
      pdf:{
        pdf:'swiftGlider.pdf',
        accessible:'swiftGliderDisability.pdf'
      }
    },
    {
      title:'The Swift Satellite Model',
      image:'sat3.png',
      page:'satelliteModel',
      description:'This booklet contains all the instructions, drawings, and descriptions needed to build a paper model of the Swift satellite. It was inspired by the model originated by the Italian Swift E/PI group, and adheres to the national technology standards. Almost all of the work to produce the English version of the model was done by Aurore Simonnet',
      internalText:["This booklet contains all the instructions, drawings, and descriptions needed to build a paper model of the Swift satellite. It was inspired by the model originated by the Italian Swift E/PI group, and adheres to the national technology standards. Almost all of the work to produce the English version of the model was done by Aurore Simonnet"],
      pdf:{
        pdf:'modelGuide.pdf',
        accessible:'modelGuideDisability.pdf'
      }
    },
    {
      title:'The Swift Sticker',
      image:'sat.png',
      page:'sticker',
      description:'A spectacular image of the Swift satellite and depiction of the mission.',
      internalText:[
        "This sticker is great for teachers, students, or anybody in the general public.", 
        "Features Swift artwork as well as text on the back of the sticker:",
        "Swift is an international mission in the NASA Explorer program to study Gamma-Ray Bursts.",
        "Gamma-ray bursts are huge explosions located in distant galaxies, but their cause remains one of the greatest mysteries in astronomy. Swift is designed to detect the bursts and autonomously point sensitive telescopes at their position to observe the fading optical, ultraviolet, and X-ray afterglows. The spacecraft is named 'Swift' because it can very rapidly -- usually in under a minute -- swing around and aim its telescopes at a gamma-ray burst. The scientific goals are to determine the origin of the bursts and to use bursts to probe the distant universe.",
        "Swift is a NASA mission that was built and will be operated by an international collaboration. Countries with major contributions are the US, Italy and the UK.",
      ], 
      pdf:{
        pdf:'swiftSticker.jpg',
        accessible:null
      }
    },
  ]

  return (
    <Layout>
        <div>
          <h1>PUBLIC OUTREACH RESOURCE AREA</h1>
          <Star />
          <div className='media-items-container'>
              {resources.map(r => 
                <div className="item-container">
                  <Link to={`/resourceSubpages/${r.page}`}>
                    <img src={`/${r.image}`} alt='resource' className='round-image'/>
                  </Link>

                  <h4>
                    <Link to={`/resourceSubpages/${r.page}`}>
                      {r.title}
                    </Link>
                  </h4>

                  <p>
                    {r.description}
                  </p>

                  <p className="media-item-buttons">
                    <Link to={`/resourceSubpages/${r.page}`}>
                      Check it out! <FaArrowAltCircleRight/>
                    </Link>
                  </p>
                </div>
              )}
              {/* new thing */}
              <div className="item-container">
                  <a href='https://svs.gsfc.nasa.gov/14255'  target='_blank' rel="noreferrer">
                    <img src='/grbapt.jpg' alt='resource' className='round-image'/>
                  </a>

                  <h4>
                    <a href='https://svs.gsfc.nasa.gov/14255'  target='_blank' rel="noreferrer">
                      NASA’s Fermi, Swift Capture Revolutionary Gamma-Ray Burst
                    </a>
                  </h4>

                  <p>
                  On Dec. 11, 2021, NASA’s Neil Gehrels Swift Observatory and Fermi Gamma-ray Space Telescope detected a blast of high-energy light from the outskirts of a galaxy around 1 billion light-years away. The event has rattled scientists’ understanding of gamma-ray bursts (GRBs), the most powerful events in the universe. This burst is called GRB 211211A.
                  </p>

                  <p className="media-item-buttons">
                    <a href='https://svs.gsfc.nasa.gov/14255'  target='_blank' rel="noreferrer">
                      Check it out! <FaExternalLinkAlt/>
                    </a>
                  </p>
                </div>
          </div>
        </div>
    </Layout>
  )
}

export default Resources
